import { PageDocumentSlugOnly } from './types/types';
import { urlJoin } from './utils/utils';

// TODO review all these constants
export const AUTHOR_NAME = 'Digital Spiders'; // Change to client name
export const COPYRIGHT_DEFAULT_YEAR = 2023;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = [DEFAULT_LANG] as const;

// WARNING When updating these three constants update in cms constants too
// WARNING When updating these three constants update in site create_redirects_file_from_cms too
export const ARTICLES_PREFIX_URL = 'articles';

export const PAGE_DOCUMENT_TYPES = ['page', 'article'] as const;

export const PAGE_TYPE_TO_PATH_FUNCTION = {
  page: (pageDoc: PageDocumentSlugOnly) => urlJoin(pageDoc.slug.current),
  article: (articleDoc: PageDocumentSlugOnly) =>
    urlJoin(ARTICLES_PREFIX_URL, articleDoc.slug.current),
} as const satisfies PageTypeToPathFunction;

/**
 *
 * Below here are constants we normally don't need to change or check
 *
 */

export const USE_COOKIES_BANNER = process.env.GATSBY_USE_COOKIES_BANNER;

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || 'null.com';

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || 'https://www.null.com';

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;

export const BRAND_GRADIENT_RGB_COLOR_STOPS = [
  { color: { a: 0, b: 194, c: 255 }, position: 0.0 },
  { color: { a: 136, b: 0, c: 242 }, position: 0.3 },
  { color: { a: 227, b: 0, c: 152 }, position: 0.6 },
  { color: { a: 249, b: 53, c: 39 }, position: 1 },
];

export const BRAND_GRADIENT_HSL_COLOR_STOPS = [
  { color: { a: 194, b: 100, c: 50 }, position: 0.0 },
  { color: { a: 274, b: 100, c: 47 }, position: 0.3 },
  { color: { a: 320, b: 100, c: 45 }, position: 0.6 },
  { color: { a: 4, b: 95, c: 56 }, position: 1 },
];
